import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import {  Modal } from "react-bootstrap";
import "./App.css";


const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const disclaimerModalJSX = (
    <Modal size="lg" show={isModalOpen} className="disclaimer" centered>
      <Modal.Body className="content-body common-dark text-center">
        <h6 className="text">
          Dear pStakers!
        </h6>
        <p className="text">
          Please be informed that the stkETH v1 implementation, soft-launched in Q2 2022, has been officially deprecated.
          Users of stkETH v1 have already been airdropped their $ETH deposits back to their wallets.
          <br/>
          For further inquiries, please reach out to us&nbsp;
          <a
              href={"https://twitter.com/pSTAKE_ETH"}
              className=""
              target={"_blank"}
              rel="noreferrer"
          >
            here
          </a>
        </p>
      </Modal.Body>
    </Modal>
  );

  return (
    <React.Fragment>
      {disclaimerModalJSX}

    </React.Fragment>
  );
};

export default App;
